<template>
	<div class="ranking-box">
		 <div class="flex alcenter space">
			 <div class="flex alcenter">
				 <i class="iconfont icondata05 ft32" style="color:#FF728B;"></i>
				 <span class="ml15 ft18 ftw600 cl-black">服务人员工时排行榜</span>
			 </div>
			 <div>
				 <a-radio-group v-model="change_appointment_time_type"  @change="changeType" button-style="solid">
						 <a-radio-button :value="1">
						   本月
						 </a-radio-button>
						 <a-radio-button :value="2">
						   本年度
						 </a-radio-button>
				 </a-radio-group>
			 </div>
		 </div>

		<div class="mt24 wxb-table-gray">
					 <a-table rowKey="serviceman_id" :columns="columns" :pagination="false" :data-source="appointment_data"
					  :loading="loading">
						<div class="flex alcenter center" slot="rank" slot-scope="rank">
							<img v-if="rank == 1" style="width: 16px; height: 26px;" src="@/assets/image/woyaoyou_num01@3x.png" />
							<img v-if="rank == 2" style="width: 16px; height: 26px;" src="@/assets/image/woyaoyou_num02@3x.png" />
							<img v-if="rank == 3"  style="width: 16px; height: 26px;" src="@/assets/image/woyaoyou_num03@3x.png" />
							<span v-if="rank > 3">{{rank}}</span>
						</div>
					 </a-table>
		</div>
	</div>
</template>


<script>

	export default{
		props:{
			appointment_time_type:{
				type:Number,
				default:1
			},
			appointment_data:{
				type:Array,
				default:function(){
					return new Array
				}
			}
		},
		data(){
			return {
				loading: false,
				change_appointment_time_type:this.appointment_time_type,
				columns: [
					{title: '排名',dataIndex:'rank',scopedSlots: {customRender: 'rank'},align: 'center'},
					{title: '姓名',dataIndex:'serviceman_name',align: 'left',scopedSlots: {customRender: 'serviceman_name'}},
					{title: '工时(h)',dataIndex: 'time_num',align: 'right',ellipsis: true},
				],
			}
		},

		methods:{
			changeType(){
				this.$emit('updateIntegralData',this.change_appointment_time_type)
			}
		}
	}
</script>

<style>
	.member-index-face {
		width: 44px;
		height: 44px;
		border-radius: 44px;
		box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.04);
		border: 2px solid #FFFFFF;
	}
</style>
