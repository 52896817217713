<template>
    <div class="ranking-box">
        <div class="flex alcenter space">
            <div class="flex alcenter">
                <i class="iconfont icondata04 ft32" style="color:#FE8340;"></i>
                <span class="ml15 ft18 ftw600 cl-black">服务项目工单排行榜</span>
            </div>
            <div>
                <a-radio-group v-model="change_project_time_type" @change="changeType" button-style="solid">
                    <a-radio-button :value="1">
                        本月
                    </a-radio-button>
                    <a-radio-button :value="2">
                        本年度
                    </a-radio-button>
                </a-radio-group>
            </div>
        </div>
        <div class="mt24 wxb-table-gray">
            <a-table rowKey="project_id" :columns="columns" :pagination="false" :data-source="project_data"
                     :loading="loading">
                <div class="flex alcenter center" slot="rank" slot-scope="rank,record">
                    <img v-if="rank == 1" style="width: 16px; height: 26px;"
                         src="@/assets/image/woyaoyou_num01@3x.png"/>
                    <img v-if="rank == 2" style="width: 16px; height: 26px;"
                         src="@/assets/image/woyaoyou_num02@3x.png"/>
                    <img v-if="rank == 3" style="width: 16px; height: 26px;"
                         src="@/assets/image/woyaoyou_num03@3x.png"/>
                    <span v-if="rank > 3">{{ rank }}</span>
                </div>

            </a-table>
        </div>


    </div>
</template>

<script>

export default {
    props: {
        project_time_type: {
            type: Number,
            default: 1
        },
        project_data: {
            type: Array,
            default: function () {
                return new Array
            }
        }
    },
    data() {
        return {
            loading: false,
            change_project_time_type: this.project_time_type,
            columns: [
                {title: '排名', dataIndex: 'rank', scopedSlots: {customRender: 'rank'}, align: 'center'},
                {title: '项目', dataIndex: 'project_name', align: 'left'},
                {title: '预约数', dataIndex: 'take_count', align: 'right', ellipsis: true},
                {title: '金额', dataIndex: 'appointment_total', align: 'right', ellipsis: true},
            ],
        }
    },
    methods: {
        changeType() {
            console.log(1);
            this.$emit('updateBalanceData', this.change_project_time_type)
        },
    }
}
</script>

<style>
.member-index-face {
    width: 44px;
    height: 44px;
    border-radius: 44px;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.04);
    border: 2px solid #FFFFFF;
}
</style>
