<template>
    <div>
        <a-row :gutter="30">
            <a-col :span="12">
                <ranking-money :project_time_type="changeSearch.project_time_type"
                               @updateBalanceData="updateBalanceData" :project_data="project_data"></ranking-money>
            </a-col>
            <a-col :span="12">
                <ranking-integral :appointment_time_type="changeSearch.appointment_time_type"
                                  @updateIntegralData="updateIntegralData"
                                  :appointment_data="appointment_data"></ranking-integral>
            </a-col>
        </a-row>
    </div>
</template>

<script>
import RankingMoney from './RankingMoney.vue';
import RankingIntegral from './RankingIntegral.vue';

export default {
    props: {
        search: {
            type: Object,
            default: {
                project_time_type: 1,
                appointment_time_type: 1,
            }
        },
        project_data: {
            type: Array,
            default: function () {
                return new Array
            }
        },
        appointment_data: {
            type: Array,
            default: function () {
                return new Array
            }
        }
    },

    components: {
        RankingMoney,
        RankingIntegral
    },
    data() {
        return {
            changeSearch: this.search,
        }
    },
    methods: {
        updateBalanceData(value) {
            this.changeSearch.project_time_type = value;
            this.$emit('updateData', this.changeSearch);
        },
        updateIntegralData(value) {
            this.changeSearch.appointment_time_type = value;
            this.$emit('updateData', this.changeSearch);
        }
    }
}
</script>

<style>
.ranking-box {
    width: 100%;
    min-height: 600px;
    background: #FFFFFF;
    border-radius: 8px;
    padding: 24px;
    line-height: 1;
}
</style>
